/**
 * 注入脚本
 * @param {string} src
 */
function injectScript(src) {
  var scriptElement = document.createElement('script');
  scriptElement.src = src;
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(scriptElement, s);
}

/**
 * 注入CNZZ 上报代码
 * @param {string} id
 */
export function injectCnzzTrack(id) {
  /**
   * TODO: 核对一下是否需要 window.cnzz_protocol
   */
  const protocol = (window.cnzz_protocol =
    'https:' == document.location.protocol ? ' https:' : ' http:');
  document.write(
    [
      `<span id='cnzz_stat_icon_${id}'></span>`,
      `<script src="${protocol}//w.cnzz.com/c.php?id=${id}"></script>`
    ].join('')
  );
}

/**
 * 注入BAIDU 上报代码
 * @param {string} id
 */
export function injectBaiduTrack(id) {
  window._hmt = window._hmt || [];
  injectScript(`https://hm.baidu.com/hm.js?${id}`);
}
