import './polyfill/array-some';
import config from './config';
import {
  captchaException,
  handleTrackMetaDataList
} from './utils';

const startTracking = () => captchaException(() => handleTrackMetaDataList(config));

(() => {
  /**
   * 如果在 commonjs 体系下, 则直接手动执行
   */
  if (typeof exports === 'object' && typeof module !== 'undefined') {
    module.exports = startTracking;
  } else {
    /**
     * 在 window global 模式下, 可以根据指定规则判断是否自执行
     */
    if (window['xfeUniversalTrack']) {
      if (window['xfeUniversalTrack'].autoStart === false) {
        window['xfeUniversalTrack'].startTracking = startTracking;
        return;
      }
    }
    startTracking();
  }
})();
