/**
 * 获取解析后的 url
 */
function getNormalizeUrl() {
  const url = window.location.href || '';
  return url.split('.').join('');
}

/**
 * 是否有最后一个出现
 * @param {string} str
 */
function hasLastIndexOf(str) {
  return arr => arr.some(match => str.lastIndexOf(match) > -1);
}

/**
 * 捕获异常
 * @param {Function} func
 */
export function captchaException(func) {
  try {
    func();
  } catch (e) {
    throw e;
  }
}

/**
 * 处理上报的 meta data 数据列表
 * @param {{checkList, actions}}} metaDataList
 */
export function handleTrackMetaDataList(metaDataList) {
  const normalizeUrl = getNormalizeUrl();
  const verifyLastIndexOf = hasLastIndexOf(normalizeUrl);
  metaDataList.some(metaData => {
    let {
      checkList,
      actions
    } = metaData;
    let isConditionMatch;
    if (typeof checkList === 'function') {
      isConditionMatch = checkList(normalizeUrl);
    } else {
      isConditionMatch = verifyLastIndexOf(checkList);
    }
    if (isConditionMatch) {
      actions.forEach(action => action());
    }
  });
}
