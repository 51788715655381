import { injectBaiduTrack, injectCnzzTrack } from './inject-track';

/**
 * 注入百度统计高阶函数
 * @param {string} id
 */
const injectBaiduTrackHOF = id => () => injectBaiduTrack(id);

/**
 * 注入CNZZ统计高阶函数
 * @param {string} id
 */
const injectCnzzTrackHOF = id => () => injectCnzzTrack(id);

export default [
  /**
   * 剑侠世界
   * @核对 - 通过
   */
  {
    checkList: ['jxsjxoyocom', 'jxsjmxoyocom'],
    actions: [
      injectBaiduTrackHOF('60522316db9ca1ef5c457a4cb5ce683c'),
      injectCnzzTrackHOF('30049454')
    ]
  },
  /**
   * 剑侠世界2
   * @核对 - 通过
   */
  {
    checkList: ['jxsj2xoyocom'],
    actions: [
      injectBaiduTrackHOF('574039057677200039c8d93d481742ce')
    ]
  },
  /**
   * 剑侠世界绿色
   * @核对 - 通过
   */
  {
    checkList: ['jxsjlsxoyocom'],
    actions: [injectBaiduTrackHOF('c306ec433976f8f5a97b890fefa1f6ac')]
  },
  /**
   * 9天
   * @核对 - 通过
   */
  {
    checkList: ['9xoyocom'],
    actions: [injectBaiduTrackHOF('3Fa2475c809402d3e50c3cee524b9d292d')]
  },
  /**
   * 灵域2
   * @核对 - 通过
   */
  {
    checkList: ['ly2xoyocom'],
    actions: [injectBaiduTrackHOF('3Fa2475c809402d3e50c3cee524b9d292d')]
  },
  /**
   * 春秋
   * @核对 - 通过
   */
  {
    checkList: ['cqxoyocom'],
    actions: [injectBaiduTrackHOF('3Fa2475c809402d3e50c3cee524b9d292d')]
  },
  /**
   * 楚乔传
   * @核对 - 通过
   */
  {
    checkList: ['cqzxoyocom'],
    actions: [injectBaiduTrackHOF('7623a6d89371f7456a42e70614fd6e27')]
  },
  /**
   * 封神榜
   * @核对 - 通过
   */
  {
    checkList(normalizeUrl) {
      return normalizeUrl.length === 10 && normalizeUrl.lastIndexOf('ifsxoyocom') > -1;
    },
    actions: [injectBaiduTrackHOF('3Fe8b7d641c27e86907364eadca72c8e6f')]
  },
  /**
   * 鬼吹灯之牧野诡事
   * @核对 - 通过
   */
  {
    checkList: ['gcdxoyocom'],
    actions: [injectBaiduTrackHOF('d34d0d12ca86c099e57a059736159d22')]
  },
  /**
   * 鬼吹灯之牧野诡事HD
   * @核对 - 通过
   */
  {
    checkList: ['guixoyocom'],
    actions: [injectBaiduTrackHOF('96e0cb0822ed499e992adf84b0ae1153')]
  },
  /**
   * 剑网3移动版
   * @核对 - 通过
   */
  {
    checkList: ['jx3mxoyocom'],
    actions: [
      injectBaiduTrackHOF('2307dec0a824009f9aa9a1648baba3be'),
      injectCnzzTrackHOF('30050295'),
      injectCnzzTrackHOF('30048117')
    ]
  },
  /**
   * 剑网3, 剑网3旧站and分流页
   * @核对 - 待审核
   */
  {
    checkList: ['jx3xoyocom'],
    actions: [
      injectBaiduTrackHOF('6a4bf253106aa865402b86b2ae40d3bc'),
      injectCnzzTrackHOF('30050295'),
      injectCnzzTrackHOF('30048117')
    ]
  },
  /**
   * 剑网1
   * @核对 - 通过
   */
  {
    checkList: ['jxxoyocom'],
    actions: [
      injectBaiduTrackHOF('ddbe296a0215979ae1cb8cb27690d1cd'),
      injectCnzzTrackHOF('30049451')
    ]
  },
  /**
   * 剑网2
   * @核对 - 通过
   */
  {
    checkList: ['jx2xoyocom'],
    actions: [
      injectBaiduTrackHOF('238b62b3389c41657e52a1b1b2f9fd3e'),
      injectCnzzTrackHOF('30049452')
    ]
  },
  /**
   * 剑网2外传
   * @核对 - 通过
   */
  {
    checkList: ['jwxoyocom'],
    actions: [injectBaiduTrackHOF('1a12a909b3264418a60036f8b3c72cef'),injectCnzzTrackHOF('30049453')]
  },
  /**
   * 月影传说
   * @核对 - 通过
   */
  {
    checkList: ['moonxoyocom'],
    actions: [injectCnzzTrackHOF('30049298')]
  },
  /**
   * 平台
   * @核对 - 通过
   */
  {
    checkList: ['xxoyoboxcom'],
    actions: [injectBaiduTrackHOF('69bb6a4f089dc8bb0426683098429b16')]
  },
  /**
   * 剑侠S手游
   * @核对 - 通过
   */
  {
    checkList: ['jsxoyocom', 'jxsxoyocom'],
    actions: [injectBaiduTrackHOF('85c1d8e2666ba43ce2accdedc27860e2')]
  },
  /**
   * 剑侠剑世手游2
   * @核对 - 通过
   */
  {
    checkList: ['js2xoyocom'],
    actions: [injectBaiduTrackHOF('bbc1ca1567a0a8966876eafb51640385')]
  },
  /**
   * 剑侠1手游2015
   * @核对 - 通过
   */
  {
    checkList: ['jxydxoyocom'],
    actions: [injectBaiduTrackHOF('0458d1ad61601f3ed2a83cf2778a6daf')]
  },
  /**
   * 少女咖啡
   * @核对 - 通过
   */
  {
    checkList: ['gcgxoyocom'],
    actions: [injectBaiduTrackHOF('942e14f1cfbdc1e67ab694c76afd2d57')]
  },
  /**
   * 装甲少女资料站
   * @核对 - 通过
   */
  {
    checkList: ['pgwikinet'],
    actions: [injectBaiduTrackHOF('d6880c26fbf11465f67e15ae6fc331bc')]
  },
  /**
   * 装甲少女
   * @核对 - 通过
   */
  {
    checkList: ['tkxoyocom'],
    actions: [injectBaiduTrackHOF('2249995b3899f243bae594a785cb18a5')]
  },
  /**
   * 自由禁区
   * @核对 - 通过
   */
  {
    checkList: ['extxoyocom'],
    actions: [injectBaiduTrackHOF('f42f3edc2859df2abca8b13b216430d1')]
  },
  /**
   * 反恐行动
   * @核对 - 待审核
   */
  {
    checkList: ['xdxoyocom', 'xdmxoyocom'],
    actions: [injectCnzzTrackHOF('30049314')]
  },
  /**
   * 特工皇妃
   * @核对 - 通过
   */
  {
    checkList: ['txoyocom'],
    actions: [injectBaiduTrackHOF('240414faeb0e75b6b45bbcc51a44480c')]
  },
  /**
   * 剑侠1新站
   * @核对 - 通过
   */
  {
    checkList: ['jxjdxoyocom'],
    actions: [injectBaiduTrackHOF('2bcc756b9b26cb5b8dd2d1fdae2de5d7')]
  },
  /**
   * 剑侠2新站
   * @核对 - 通过
   */
  {
    checkList: ['jx2xzxoyocom'],
    actions: [injectBaiduTrackHOF('7670afad36fb5af9a31e3b62fe05a4ba')]
  },
  /**
   * 麻辣江湖新站
   * @核对 - 通过
   */
  {
    checkList: ['newmjxoyocom'],
    actions: [injectBaiduTrackHOF('f08af2ef346cabb63c0f9029eaf2d274')]
  },
  /**
   * 血灵决新站
   * @核对 - 通过
   */
  {
    checkList: ['xljxoyocom'],
    actions: [injectBaiduTrackHOF('47feef7857f6d628bc935349d8d84037')]
  },
  /**
   * 迷宫
   * @核对 - 通过
   */
  {
    checkList: ['migongxoyocom'],
    actions: [injectBaiduTrackHOF('9f07fa631e0eb53300bf9999b3f95f68')]
  },
  /**
   * 成吉思汗
   * @核对 - 通过
   */
  {
    checkList: ['hanxoyocom'],
    actions: [injectBaiduTrackHOF('53c39f365afa1a64bfbbc37d41060a99')]
  },
  /**
   * 滚蛋吧僵尸
   * @核对 - 通过
   */
  {
    checkList: ['gdbxoyocom'],
    actions: [injectBaiduTrackHOF('6fdcb51e7bc5df3563bbc919b9a8e76a')]
  },
  /**
   * 锤子三国
   * @核对 - 通过
   */
  {
    checkList: ['czxoyocom'],
    actions: [injectBaiduTrackHOF('8c1b9bc77c4227db7c2e539f590f998c')]
  },
  /**
   * 魔域手游
   * @核对 - 通过
   */
  {
    checkList: ['mysyxoyocom'],
    actions: [injectBaiduTrackHOF('5ad6a6313849a3f45f204c9b47b90d06')]
  },
  /**
   * 牌乐门
   * @核对 - 通过
   */
  {
    checkList: ['qyqxoyocom'],
    actions: [injectBaiduTrackHOF('5ad6a6313849a3f45f204c9b47b90d06')]
  },
  /**
   * 三国战神
   * @核对 - 待审核
   */
  {
    checkList: ['sgzsxoyocom'],
    actions: [injectCnzzTrackHOF('1000346399')]
  },
  /**
   * 正三国（原问鼎天下）
   * @核对 - 待审核
   */
  {
    checkList: ['zsgxoyocom'],
    actions: [
      injectBaiduTrackHOF('faf00857f2e1b43bcf02bbbfe85aa55d')
    ]
  },
  /**
   * 猎魔
   * @核对 - 通过
   */
  {
    checkList: ['lmxoyocom'],
    actions: [injectBaiduTrackHOF('1cc4c83a6a933cbab7ddce4fe3c07693')]
  },
  /**
   * 西山居2018ChinaJoy
   */
  {
    checkList: ['cjxoyocom'],
    actions: [injectBaiduTrackHOF('95869b27dc8638d1dd9224fa5a551b78')]
  },
  /**
   * 西山居剑三域名
   */
  {
    checkList: ['jx3seasungamecom'],
    actions: [injectBaiduTrackHOF('1c16f604c0cf46b79babb6b2d1f3bc98')]
  },
  /**
   * 西山居剑二域名
   */
  {
    checkList: ['js2seasungamecom'],
    actions: [injectBaiduTrackHOF('4ec5e95a66a5ec0996345446cd5469ca')]
  },
  /**
   * daily 下的 xoyo域名
   */
  {
    checkList: ['dailyxoyocom'],
    actions: [injectBaiduTrackHOF('3849b56273677d07631033ab73eb4b79')]
  },
  /**
   * 指尖江湖
   */
  {
    checkList: ['zjjhxoyocom'],
    actions: [injectBaiduTrackHOF('76014e4dead003e34666ed3acaef7ba1')]
  },
  /**
   * 指尖江湖手游百度贴吧落地页
   */
  {
    checkList: ['xyxoyocom'],
    actions: [injectBaiduTrackHOF('f85afbe54c3efbb1cf475555adcd203c')]
  },
  /**
   * E3大展
   */
  {
    checkList: ['e3xoyocom'],
    actions: [injectBaiduTrackHOF('069623bd9cb5035257123626fb2052fe')]
  },
  /**
   * 西山居实验室
   */
  {
    checkList: ['scxishanjucom'],
    actions: [injectBaiduTrackHOF('0d272a7be6ac5f5ac4cefa58f577fd96')]
  },
  /**
   * 指尖江湖
   */
  {
    checkList: ['zjjhxoyocom'],
    actions: [injectBaiduTrackHOF('76014e4dead003e34666ed3acaef7ba1')]
  },
  /**
   * 东京游戏展展会
   */
  {
    checkList: ['tgsseasungamecom'],
    actions: [injectBaiduTrackHOF('4e87614da703b4128d81d501ce73aa3f')]
  },
  /**
   * 剑侠世界3手游
   */
  {
    checkList: ['js3xoyocom'],
    actions: [injectBaiduTrackHOF('d9cb2981b733e66491795242c8668516')]
  },
  /**
   * 指尖自走棋
   */
  {
    checkList: ['zzqxoyocom'],
    actions: [injectBaiduTrackHOF('abcde3bdc4c73dec926f77c294365123')]
  },
  /**
   * 指尖对弈
   */
  {
    checkList: ['dyxoyocom'],
    actions: [injectBaiduTrackHOF('7a65cb25bfeb85bd082c1f8bcbea6498')]
  },
  /**
   * 买量广告投放
   */
  {
    checkList: ['asxoyocom'],
    actions: [injectBaiduTrackHOF('19f93b9ef5dbcab2b9b8d5581466a620')]
  },
  /**
   * 剑网3怀旧服缘起
   */
  {
    checkList: ['jx3yqxoyocom'],
    actions: [injectBaiduTrackHOF('f06047971923d383f73e784b227cebf6')]
  },
  /**
   * 猫项目 Project Cat
   */
  {
    checkList: ['projectcatxoyocom'],
    actions: [injectBaiduTrackHOF('4df1ae7069cc99f86527287ee02050d1')]
  },
  /**
   * 尘白禁区
   */
  {
    checkList: ['project-snowcom'],
    actions: [injectBaiduTrackHOF('bdf7a8e996dfc24622be8b9a342a54fd')]
  },
  /**
   * code break 机甲概念站
   */
  {
    checkList: ['xinhuozgcom'],
    actions: [injectBaiduTrackHOF('e0383255d83bfe821c0fe3c60127e257')]
  },
  /**
   * code break 机甲备用站
   */
  {
    checkList: ['xinhuogycom'],
    actions: [injectBaiduTrackHOF('d13ec47b714919212a0d698319cd22c5')]
  },
];
